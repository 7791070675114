import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "components/Headers/Header";
import { Formik } from "formik";
import UseFullPageLoader from "utils/UseFullPageLoader";
import { importWebtoon } from "utils/endpoint";

const ImportWebtoon = () => {
  const [loader, showLoader, hideLoader] = UseFullPageLoader();
  const [loading, setLoading] = useState(false);
  const [webtoonDataFile, setWebtoonDataFile] = useState("");
  const [webtoonImageFile, setWebtoonImageFile] = useState("");
  const [webtoonDataFileError, setWebtoonDataFileError] = useState(null);
  const [webtoonImageFileError, setWebtoonImageFileError] =
    useState(null);
  const navigate = useNavigate();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result.split(",")[1];
        if (event.target.name === "webtoon_data") {
            setWebtoonDataFile(base64);
        } else {
            setWebtoonImageFile(base64);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSampleCsvFileDownload = () => {
    const link = document.createElement("a");
    console.log(link);
    link.href = `${process.env.PUBLIC_URL}/sample-files/webtoon-sample-data.csv`;
    link.download = "webtoon-sample-data.csv";
    link.click();
  };

  const handleSampleImageZipFileDownload = () => {
    const link = document.createElement("a");
    link.href = `${process.env.PUBLIC_URL}/sample-files/webtoon-sample-images.zip`;
    link.download = "webtoon-sample-images.zip";
    link.click();
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        webtoon_data: null,
        webtoon_images: null,
      }}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        if (!values.webtoon_data) {
            setWebtoonDataFileError("Data file is required");
        }
        if (!values.webtoon_images) {
            setWebtoonImageFileError("Image file is required");
        }
        if (!values.webtoon_data && !values.webtoon_images) {
          return false;
        }
        setLoading(true);
        showLoader();

        const finalObj = {
          webtoon_data: webtoonDataFile,
          webtoon_images: webtoonImageFile,
        };
        console.log(finalObj);
      
        const result = await importWebtoon(finalObj);
        if (result?.isSuccess) {
          toast.success("Webtoon imported successfully!");
          setLoading(false);
          hideLoader();
          resetForm();
          navigate("/admin/webtoon/webtoon-list");
        } else {
          setSubmitting(false);
          setLoading(false);
          hideLoader();
          return toast.error(
            result.message || `Something went wrong, please try again letter!`
          );
        }
      }}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <>
            <Header />

            <Container className="mt--7" fluid>
              <Row>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <Row>
                        <Col lg="6">
                          <h1 className="mb-0">Import Webtoon</h1>
                        </Col>
                        <Col lg="6" className="add_btn text-right">
                          <Button
                            color="primary"
                            className=""
                            onClick={() => {
                              navigate("/admin/webtoon/webtoon-list");
                            }}
                          >
                            <span className="me-2">Back</span>
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex mb-1">
                              <label className="form-control-label required">
                                Webtoon Data (*.CSV)
                              </label>
                              <Button
                                className="btn-sm ml-auto"
                                color="primary"
                                title="Download sample csv file of Manga"
                                onClick={async () => {
                                  handleSampleCsvFileDownload();
                                }}
                              >
                                <i class="fa fa-download"></i>
                              </Button>
                            </div>
                            <div className="form-control w-100 m-0 ">
                              <label
                                className="mb-0 text-muted"
                                style={{ cursor: "pointer" }}
                              >
                                {values?.webtoon_data
                                  ? values?.webtoon_data
                                  : "Click here to upload csv file"}
                                <i
                                  className="fa fa-upload ml-2"
                                  aria-hidden="true"
                                ></i>
                                <Input
                                  id="webtoon_data"
                                  type="file"
                                  accept=".csv"
                                  name="webtoon_data"
                                  className="form-control d-none"
                                  onChange={(event) => {
                                    if (event?.target?.files[0]?.name) {
                                      setFieldValue(
                                        "webtoon_data",
                                        event?.target?.files[0]?.name || null
                                      );
                                      setWebtoonDataFileError(null);
                                    }
                                    handleFileChange(event);
                                  }}
                                />
                              </label>
                            </div>
                            {webtoonDataFileError && (
                              <div className="text-danger text-align-top-center mt-1">
                                {webtoonDataFileError}
                              </div>
                            )}
                          </FormGroup>
                        </Col>

                        <Col lg="3">
                          <FormGroup>
                            <div className="d-flex mb-1">
                              <label className="form-control-label required">
                                Images (*.Zip)
                              </label>
                              <Button
                                className="btn-sm ml-auto"
                                color="primary"
                                title="Download sample images zip file of manga"
                                onClick={async () => {
                                  handleSampleImageZipFileDownload();
                                }}
                              >
                                <i class="fa fa-download"></i>
                              </Button>
                            </div>
                            <div className="form-control w-100 m-0 ">
                              <label
                                className="mb-0 text-muted"
                                style={{ cursor: "pointer" }}
                              >
                                {values?.webtoon_images
                                  ? values?.webtoon_images
                                  : "Click here to upload images"}
                                <i
                                  className="fa fa-upload ml-2"
                                  aria-hidden="true"
                                ></i>
                                <Input
                                  id="webtoon_images"
                                  type="file"
                                  accept=".zip"
                                  name="webtoon_images"
                                  className="form-control d-none"
                                  onChange={(event) => {
                                    if (event?.target?.files[0]?.name) {
                                      setFieldValue(
                                        "webtoon_images",
                                        event?.target?.files[0]?.name || null
                                      );
                                      setWebtoonImageFileError(null);
                                    }
                                    handleFileChange(event);
                                  }}
                                />
                              </label>
                            </div>
                            {webtoonImageFileError && (
                              <div className="text-danger text-align-top-center mt-1">
                                {webtoonImageFileError}
                              </div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row>
                        <Col lg="3">
                          <Button
                            type="submit"
                            className="my-3 w-100"
                            color="primary"
                          >
                            {loading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please Wait..
                                <span className="spinner-border spinner-border-sm align-middle ms-3 mb-1"></span>
                              </span>
                            )}
                            {!loading && (
                              <span className="indicator-label">Import</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </div>
              </Row>
            </Container>
            {loader}
          </>
        </form>
      )}
    </Formik>
  );
};

export default ImportWebtoon;

